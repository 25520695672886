<template>
	<div class="home">
		<van-swipe :autoplay="3000">
			<van-swipe-item v-for="(image, index) in imgList" :key="index">
				<img class="shop-image" :src="imageUrl+image.image"/>
			</van-swipe-item>
		</van-swipe>
		<div class="twoList">
			<div class="one">
				<div class="left-one">
					<h6>绑定信用卡领80元</h6>
					<p>立即领取</p>
				</div>
				<img src="../../assets/1.png" alt="" />
			</div>
			<div class="two">
				<div class="left-one">
					<h6>新用户限时福利</h6>
					<p>立即领取</p>
				</div>
				<img src="../../assets/2.png" alt="" />
			</div>
		</div>
		<!-- <div class="conList">
			<div>
				<img src="../../assets/redPacket.png" alt="">
				<p>消费赚红包</p>
			</div>
			<div>
				<img src="../../assets/money.png" alt="">
				<p>激励金提现</p>
			</div>
			<div>
				<img src="../../assets/cash.png" alt="">
				<p>新用户62大礼包</p>
			</div>
		</div> -->
		<van-tabs v-model="active" sticky @click="toggle" color="#ffa000">
			<van-tab v-for="item in shopType" :title="item.name">
				<van-list v-if="goodsList.length != 0" v-model="isUpLoading" :finished="upFinished"
					:immediate-check="false" :offset="10" finished-text="我是有底线的" @load="onLoadList"
					style="padding:15px 15px 50px 15px;height: 100%;">

					<div v-for="item in goodsList" class="goods-item" @click="goShopDetail(item.id)">
						<div class="left-dis">
							<!-- <p></p> -->
							<div class="info-left">
								<img :src="imageUrl+item.image" alt="" class="goods-image">
								<div class="rig-rig">
									<p class="title">{{item.name}}</p>
									<p class="shopName">{{item.shop_name}}</p>
									<!-- <p class="title" v-if="item.type == 1">满折</p>
									<p class="title" v-else>满减</p> -->
								</div>
							</div>
						</div>
						<div class="right-dis">
							<p class="today-res">今日剩余<span style="color: #bd0407;">{{parseInt(item.stock/item.count*100)}}</span>%</p>
							<div class="progress">
								<span class="progressing" v-bind:style=" 'width:' + (item.stock/item.count)*100 +'%' ">
								</span>
							</div>
							<p class="btn-ck">查看详情</p>
						</div>
					</div>
				</van-list>
				<van-empty description="暂无数据" v-else />
			</van-tab>
		</van-tabs>
	</div>
</template>
<script>
	import {
		index
	} from '@/api'
	import {
		toUrl
	} from '@/utils/tools'
	import {
		getIconList

	} from '@/api/index'
	import {
		discountslist,
		discountImg
	} from '@/api/user'
	import {
		Empty
	} from 'vant';
	
	export default {
		data() {
			return {
				// images: [
				// 	'https://img01.yzcdn.cn/vant/apple-1.jpg',
				// 	'https://img01.yzcdn.cn/vant/apple-2.jpg',
				// ],
				imageUrl: this.url.imageUrl,
				shopType: '',
				nullTip: '空空如也~',
				limit: 10, // 每页条数
				page: 1, // 页码
				type: 1,
				goodsList: [], // 请求数据
				isDownLoading: false, // 下拉刷新
				isUpLoading: false, // 上拉加载
				upFinished: false, // 上拉加载完毕
				offset: 100, // 滚动条与底部距离小于 offset 时触发load事件
				imgList:''
			}
		},
		mounted() {
			this.getShopType()
			this.discountslist()
			this.discountImg()
		},
		methods: {
			goShopDetail(id) {
				this.$router.push({
					path: '/discountDetails',
					query: {
						id: id
					}
				})
			},
			toggle(name) {
				this.type = name + 1
				this.page = 1
				this.limit = 10
				this.goodsList = []
				this.discountslist()
			},
			getShopType() {
				getIconList().then((res) => {
					// console.log(res) 
					this.shopType = res.data
				})
			},
			discountslist() {
				this.$toast.loading({
					message: '加载中...',
					forbidClick: true,
				});
				let params = {}
				params.page = this.page
				params.limit = this.limit
				params.trade_id = this.type
				discountslist(params).then((res) => {
					console.log(res)
					const rows = res.data.data
					// console.log(rows.length)
					if (rows.length === 0) {
						// 加载结束 
						this.upFinished = true
						return
					}
					if (rows.length < this.limit) {
						// 最后一页不足10条的情况
						this.upFinished = true
					}
					// 处理数据
					if (this.page === 1) {
						this.goodsList = rows
					} else {
						this.goodsList = this.goodsList.concat(rows)
					}
					// console.log(this.goodsList)
					this.$toast.clear()
				})
			},
			discountImg(){
				let params ={}
				params.position = "coupon"
				discountImg(params).then((res) => {
					console.log(res) 
					this.imgList = res.data
				})
			},
			onDownRefresh() {

			},
			// 上拉加载请求方法
			onLoadList() {
				this.page++
				this.discountslist()
			},
		}
	}
</script>
<style lang="less" scoped>
	@import "~@/assets/style/preset.less";

	.van-swipe {
		width: 100%;
		height: 150px;
	}

	.shop-image {
		width: 100%;
		height: 100%;
	}

	.dis-banner {
		width: 90%;
		margin: 0 auto;

		img {
			width: 100%;
		}
	}

	.twoList {
		width: 90%;
		height: 60px;
		display: flex;
		justify-content: space-between;
		margin: 10px auto;

		.one {
			width: 48%;
			height: 100%;
			background-color: #fcd9c7;
			border-radius: 8px;
			display: flex;
			align-items: center;
			justify-content: space-around;

			.left-one {
				h6 {
					margin: 0;
					font-size: 12px;
					color: #f0181c;
				}

				p {
					margin: 0;
					font-size: 12px;
					width: 60px;
					height: 20px;
					line-height: 20px;
					text-align: center;
					border-radius: 15px;
					background: #fc8d4d;
					color: #FFFFFF;
					margin: 0 auto;
					margin-top: 5px;
				}
			}

			img {
				width: 50px;
				height: 50px;
			}
		}

		.two {
			width: 48%;
			height: 100%;
			background-color: #fabaae;
			border-radius: 8px;
			display: flex;
			align-items: center;
			justify-content: space-around;

			.left-one {
				h6 {
					margin: 0;
					font-size: 12px;
					color: #f0181c;
				}

				p {
					margin: 0;
					font-size: 12px;
					width: 60px;
					height: 20px;
					line-height: 20px;
					text-align: center;
					border-radius: 15px;
					background: #ff0303;
					color: #FFFFFF;
					margin: 0 auto;
					margin-top: 5px;
				}
			}

			img {
				width: 50px;
				height: 50px;
			}
		}
	}

	.conList {
		display: flex;
		width: 90%;
		height: 25px;
		padding: 5px 0;
		background-color: #FFFFFF;
		box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.15);
		border-radius: 5px;
		margin: 10px auto;

		div:nth-child(1) {
			width: 30%;
			justify-content: center;
			display: flex;
			align-items: center;
			border-right: solid 1px rgba(0, 0, 0, 0.15);

			img {
				width: 18px;
				height: 18px;
			}

			p {
				font-size: 12px;
				margin-left: 2px;
			}
		}

		div:nth-child(2) {
			width: 30%;
			justify-content: center;
			display: flex;
			align-items: center;
			border-right: solid 1px rgba(0, 0, 0, 0.15);

			img {
				width: 18px;
				height: 18px;
			}

			p {
				font-size: 12px;
				margin-left: 2px;
			}
		}

		div:nth-child(3) {
			width: 40%;
			justify-content: center;
			display: flex;
			align-items: center;
			border-right: solid 1px rgba(0, 0, 0, 0.15);

			img {
				width: 18px;
				height: 18px;
			}

			p {
				font-size: 12px;
				margin-left: 2px;
			}
		}
	}

	.home {
		background-color: #F6F6F6;
		.options-area {
			display: flex;
			flex-wrap: wrap;
			background-color: #FFFFFF;

			.options {
				display: flex;
				align-items: center;
				flex-direction: column;
				width: 20%;
				margin-bottom: 10px;

				.options-icon {
					width: 55px;
					height: 55px;
				}

				.options-name {
					margin-top: 5px;
					font-size: 12px;
					color: #666666;
				}
			}
		}

		.goods-item {
			display: flex;
			align-items: flex-start;
			background-color: #FFFFFF;
			height: 88px;
			border-radius: 5px;
			margin-bottom: 10px;
			// padding: 5px 15px;
			justify-content: flex-start;

			.left-dis {
				font-size: 14px;
				width: 70%;
				height: 100%;
				padding: 0 10px;
				display: flex;
				align-items: center;
				p {
					margin: 0;
					padding: 0;

					span {
						color: #888;
						font-size: 13px;
						margin-left: 5px;
					}
				}

				.info-left {
					display: flex;
					align-items: center;

					.goods-image {
						width: 64px;
						height: 60px;
						border-radius: 6px;
					}

					.rig-rig {
						margin-left: 15px;
						.title {
							font-size: 16px;
							color: red;
							font-weight: 600;
						}
						.shopName{
							color: #777;
							font-size: 14px;
							margin-top: 5px;
						}

						.bankName {
							color: #555;
							font-size: 13px;
							margin-top: 8px;
						}
					}
				}
			}

			.right-dis {
				width: 30%;
				height: 100%;
				border-left: dotted 1px red;
				background-color: #fdedee;
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: center;

				.today-res {
					font-size: 12px;
					color: red;
					margin: 0;
				}

				.process {
					width: 60px;
					height: 3px;
					// margin: 0 auto;
					margin: 5px;
					// border: black 4px solid;
					background-color: red;

					div {
						height: 3px;
						background: red;
					}
				}

				.btn-ck {
					font-size: 12px;
					border: solid 2px red;
					color: red;
					padding: 2px 3px;
					margin-top: 5px;
					border-radius: 5px;
				}
			}
		}
	}

	.progress {
		width: 60%;
		height: 3px;
		background: #ac0000;
		// border: 1px solid #ecc353;
		border-radius: 10px;
		text-align: center;
		color: #fff;
		margin: 3px 0;

		.progressing {
			position: relative;
			float: left;
			margin: 0 auto;
			height: 3px;
			background: red;
			border-radius: 10px;

			.percentage {
				margin-left: 50px;
			}
		}
	}

</style>
