import request from '@/utils/request'

// 登录方法
export function index() {
    return request({
        url: '/index',
        method: 'get',
        isLogin: false,
    })
}

// 登录方法
export function product(params) {
    return request({
        url: '/product/rec',
        method: 'get',
        params: params,
        isLogin: false,
    })
}

// 登录方法
export function about() {
    return request({
        url: '/about',
        method: 'get',
        isLogin: false,
    })
}
//获取首页图标列表
export function getIconList() {
    return request({
        url: '/trade/index',
        method: 'get',
        isLogin: false,
    })
}
//获取首页商品列表
export function getGoodsList(params) {
    return request({
        url: '/product/recom',
        method: 'get',
		params:params,
        isLogin: false,
    })
}
//获取首页轮播图
export function getIndexImage(params) {
    return request({
        url: '/ad/lst',
        method: 'get',
		params:params,
        isLogin: false,
    })
}
//获取首页城市
export function getIndexCity(params) {
    return request({
        url: '/area/info',
        method: 'get',
		params:params,
        isLogin: false,
    })
}

// 搜索
export function getSearch(data) {
    return request({
        url: '/home/search',
        method: 'get',
        params:data,
		isLogin: true,
    })
}
// 联想搜索
export function getSearchTip(data) {
    return request({
        url: '/home/searchTip',
        method: 'get',
        params:data,
		isLogin: true,
    })
}
